








































































































import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { newCarFinish } from '@/components/molecules/select-search/store/carFinish'
import { CarFinish } from '@/api/interfaces/carfinish'

const IRI_EN_COURS_CREATION = 'en_cours_creation'

export default defineComponent({
  name: 'DialogCreateEditCarFinish',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    carFinishEdit: {
      type: Object,
      required: false,
    },
    carModel: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      isSubmiting: false,
      carFinish: newCarFinish(),
    }
  },
  computed: {
    isEditingCarFinish (): boolean {
      return (this.carFinish['@id'] !== undefined && this.carFinish['@id'] !== '')
    },
  },
  created () {
    if (this.carFinishEdit && this.carFinishEdit['@id']) {
      (this.carFinish as CarFinish) = this.carFinishEdit as CarFinish
    } else {
      this.carFinish = newCarFinish()
    }
  },
  watch: {
    carFinishEdit: {
      handler (newVal) {
        if (newVal && newVal['@id']) {
          (this.carFinish as CarFinish) = { ...this.carFinishEdit as CarFinish }
        } else {
          this.carFinish = newVal
        }
      },
    },
  },
  methods: {
    ...mapActions('carFinish', {
      save: 'save',
    }),
    async onSubmit () {
      this.isSubmiting = true
      try {
        if (this.carFinish['@id'] && this.carFinish['@id'] !== IRI_EN_COURS_CREATION) {
          // case update carFinish when edit carModel
          await this.save(this.carFinish)
          this.$emit('carFinishUpdated', this.carFinish, this.carFinishEdit)
        } else {
          if (this.carModel && this.carModel['@id']) {
            // case create carFinish when edit carModel
            this.carFinish.carModel = this.carModel['@id']
            this.carFinish = await this.save(this.carFinish)
          }
          if (this.carFinish['@id'] !== IRI_EN_COURS_CREATION) {
            // case create carFinish when create carModel
            if (!this.carFinish['@id']) {
              this.carFinish['@id'] = IRI_EN_COURS_CREATION
            }
            this.$emit('carFinishCreated', this.carFinish)
          } else {
            // case edit carFinish when create carModel
            this.$emit('carFinishUpdated', this.carFinish, this.carFinishEdit)
          }
        }
      } finally {
        this.clear()
        this.$emit('close')
      }
    },
    clear () {
      this.carFinish = newCarFinish()
      this.isSubmiting = false
      const observer = this.$refs.observerFormCarFinish as InstanceType<typeof ValidationObserver>
      observer.reset()
    },
  },
})
